import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User, UserModel } from '@app/models/user.model';
import { ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url= ENV.API_ENDPOINT;

  userToken: any;
  token: string;
  currentUser: User;

  constructor(private http: HttpClient) {
    this.token= '';
  }


  public login( usuario: UserModel): Observable<any> {
    return this.http.post<any>( `${this.url}/login`, usuario);
  
  }
  
  public usuario(id: any){
    this.http.get<any>(`${ENV.API_ENDPOINT}/user?id=${id}`).subscribe(
      (resp) => {
        this.currentUser= resp.data;
        localStorage.setItem('user', JSON.stringify(this.currentUser));
        localStorage.setItem('loggedIn', 'true');
      }
    )
  }



} 

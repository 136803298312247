import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArchivoConcesionarioDTO } from '@app/models/archivo.model';
import { ArchivosService } from '@app/services/archivos/archivos.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gestor-concesionario',
  templateUrl: './gestor-concesionario.component.html',
  styleUrls: ['./gestor-concesionario.component.css']
})
export class GestorConcesionarioComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  public archivos: ArchivoConcesionarioDTO[]=[];
  public concesionario: any;

  ModalResponseActivate= false;
  response= {};
  archivosNuevos=false;

  constructor(
    private archivoService: ArchivosService,
    private _route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.consultarConcesionario();
    
    this.filtrarArchivos();
  }

  consultarConcesionario(){
    this.subscriptions.add(this.archivoService.concesionario(this._route.snapshot.paramMap.get('id')).subscribe( (resp)=>{
      this.concesionario= resp.data
    }))
  }

  filtrarArchivos(){
    const data = {
      "id": null,
      "nombre": null,
      "nombreArchivo": null,
      "estado": null,
      "userCrea": null,
      "concesionario": this._route.snapshot.paramMap.get('id'),
      "fechaCreacion": null,
      "fechaModificado": null,
      "fechaEliminado": null,
      "page": null,
      "size": null
    }
    this.subscriptions.add(this.archivoService.filtrarArchivoConcesionario(data).subscribe( (resp)=>{
      this.archivos= resp.data
      if(this.archivos.length > 0){
        this.archivosNuevos= true;
      }
    }))
  }

  cambiarEstado(id:any, value: any){
    this.subscriptions.add(this.archivoService.estadoArchivoConcesionario(id, value).subscribe( (resp)=>{
      this.response= resp;
      this.ModalResponseActivate= true;
      this.filtrarArchivos();
    }))
  }

  activarModalResponse(){
    this.ModalResponseActivate = !this.ModalResponseActivate;
  }

  public getDownloadUrl(archivo: any) {

    this.archivoService.getDownloadUrlConcesionario( btoa(archivo.id)).subscribe((response: any) => {
     
        if (response.status === "OK" && response.codigoRespuesta === "0") {
          const dataFile = response.data.urlDescarga;
          this.downloadFile(dataFile, archivo);
        } else {
          console.warn(response.mensaje);
        }
      });

  }

  public downloadFile(dataFile: string, archivo: any) {
    const blob = this.base64ToBlob(dataFile, archivo.nombreArchivo);
    const url = window.URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = archivo.nombre;
    a.style.display = 'none';
    
    document.body.appendChild(a);
    a.click();
    
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private base64ToBlob(base64: string, contentType: string) {

    const fileType = contentType.split('.')[1];

    if(fileType === 'docx'){
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ;
    }
    if(fileType === 'pdf'){
      contentType = 'application/pdf' ;
    }
    if(fileType === 'xlsx'){
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ;
    }
    if(fileType === 'xls'){
      contentType = 'application/vnd.ms-excel' ;
    }
    if(fileType === 'doc'){
      contentType = 'application/msword' ;
    }

    
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}

import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '@app/models/user.model';
import { AuthService } from '@app/services/auth/auth.service';
import { TokenService } from '@app/services/auth/token.service';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {



  public subscriber: Subscription;

  public menu= true;
  public scroll: boolean = false;
  public logedin= false;
  public user: User= {
    id: 0,
    email: '',
    nombre: '',
    apellido: '',
    tipo: '',
    detalle: [{}]
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    const scrollY =  window.scrollY || window.pageYOffset;
    this.scroll = scrollY > 0;
  }

  constructor(
    @Inject(DOCUMENT) document: any,
    private authService: AuthService,
    private tokenService: TokenService,
    private cd: ChangeDetectorRef,
    private router: Router
    ) 
  {
    this.subscriber = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if (event['url'].includes('/login/restorePassword/')){
        this.menu=false;
      }else{
        this.menu=true;
      }
    })
  
  }


  isActiveUser() {
    if(localStorage.getItem('loggedIn') == 'true'){
      this.logedin = true;
      this.user=this.authService.currentUser;  

      return false;

    }
    return true;
  }


  logout(){
    this.tokenService.logOut();
    this.logedin= false;
  }



}

import { Injectable } from '@angular/core';
import { TokenService } from '../auth/token.service';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV, publicUrls } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorsService  implements HttpInterceptor {

  private url=  ENV.API_ENDPOINT +'/login';

  constructor(private tokenService: TokenService) {}

  private isPrivate(url: string): boolean {
    
    let isPrivate = true;
    publicUrls.forEach((path) => {
      if (url.includes(path)) {
        isPrivate = false;
      }
    });

    return isPrivate;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();

    
    if (this.isPrivate(req.url)) {

      if(!this.tokenService.notExpired()){
        this.tokenService.logOut();
      }
      

      if (req.url == this.url) {
        
        return next.handle(req);
  
      } else {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
  
        const request = req.clone({
          headers,
        });
  
        return next.handle(request);
        
      }
    }else {      
      return next.handle(req);
    }

  }
}

<section class="container">
  <div class="content py-5 px-3 my-4 mx-4">
    <div *ngIf="!valido" class="row flex-row text-center">
      <div class="col-12">
          <h6 class="mb-2">Token no válido</h6>
          <span class="h6">{{mensaje}}</span>
      </div>
    </div>
      <div *ngIf="valido" class="row flex-row text-center">
          <div class="col-12">
              <h6 class="">Recupero de contraseña</h6>
              <span class="h6">Por favor ingrese su nueva contraseña</span>
          </div>
          <!-- FORM RECUPERACION CONTRASEÑA -->
          <div class="flex text-center">
              <form class="p-3" [formGroup]="recoverPass" (ngSubmit)="cambiarPass()">
                  <div class="justify-content-center" style="display: grid;">
                      <div class="form-group mb-1-5 ">
                          <div class="form-floating"> 
                              <input type="password" class="form-control" placeholder="12345678" formControlName="nuevaClave">
                              <label>Nueva contraseña</label>
                          </div>
                          <div *ngIf="recoverPass.get('nuevaClave')?.touched && recoverPass.get('nuevaClave')?.errors?.['minlength']" style="color: red; margin-top: 5px;">
                                  La clave debe tener al menos 8 dígitos
                          </div>
                      </div>
                      <div class="form-group mb-1-5 ">
                          <div class="form-floating">
                              <input type="password" class="form-control" placeholder="name@example.com" formControlName="repeticionClave">
                              <label>Repita la contraseña</label>
                          </div>
                          <div *ngIf="recoverPass.get('repeticionClave')?.touched && recoverPass.get('repeticionClave')?.errors?.['minlength']" style="color: red; margin-top: 5px;">
                                  La clave debe tener al menos 8 dígitos
                          </div>
                      </div>
                      <div style="color: red;">
                         {{mensaje}}
                      </div>
                      <div class="form-group p-0-5 col-12">
                          <button type="submit" class="btn btn-primary btn-default" [disabled]="recoverPass.invalid"> cambiar contraseña </button>
                      </div>
                  </div>
              </form>
          </div>
          <!-- ---------------------------- -->
      </div>
  </div>
</section>

<div *ngIf="ModalResponseActivate">
  <div class="modal-response" >
      <div class="cookies-personalizar-content">
          <div class="cookies-personalizar-header">
              <div class="titulo">
                  <h5 class="bold"></h5>
              </div>
              <button class="btn-cerrar h-2 bold" (click)="activarModalResponse()">X</button>
          </div>
          <div class="body">
              <div class="modal-form form-content mx-3 mb-2">
                  <h6>{{respEstado}}</h6>
              </div>
              <div class="modal-form form-content mx-3">

                  <p class="body-text-m">{{respMensaje}}</p>
              </div>
          </div>
      </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ArchivosService } from '@app/services/archivos/archivos.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, Subscription, catchError, switchMap, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'app-documentacion',
  templateUrl: './documentacion.component.html',
  styleUrls: ['./documentacion.component.scss']
})
export class DocumentacionComponent implements OnInit {

  public subscriptions: Subscription = new Subscription();
  private destroy$ = new Subject<void>();
  public archivos=[];

  constructor(
    private archivoService: ArchivosService,
    private recaptcha: ReCaptchaV3Service) { }


  ngOnInit() {
    this.conusltarDatos();
  }
  

  public getDownloadUrl(archivo: any) {
    const recaptchaAction = 'importantAction';

    this.recaptcha.execute(recaptchaAction)
      .pipe(
        switchMap((token: string) => {

          const solicitud = {
            idBase64: archivo.idBase64,
            token: token
          }

          return this.archivoService.getDownloadUrl(solicitud)
            .pipe(
              takeUntil(this.destroy$),
              catchError((err: any) => {
                return throwError(() => console.warn(err));
              })
            );
        })
      )
      .subscribe((response: any) => {
        if (response.status === "OK" && response.codigoRespuesta === "0") {
          const dataFile = response.data.urlDescarga;
          this.downloadFile(dataFile, archivo);
        } else {
          console.warn(response.mensaje);
        }
      });

  }

  public downloadFile(dataFile: string, archivo: any) {
    const blob = this.base64ToBlob(dataFile, "application/pdf");
    const url = window.URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = archivo.nombre;
    a.style.display = 'none';
    
    document.body.appendChild(a);
    a.click();
    
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private base64ToBlob(base64: string, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  conusltarDatos(){
    this.subscriptions.add(this.archivoService.consultarArchivosPublico().subscribe(
      (resp) => {
        if(resp.codigoRespuesta == '0'){
          this.archivos = resp.data;
        }else{
          console.log(resp.mensaje)
        }

      }
    ))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { EnvInterface } from './env';
export const ENV: EnvInterface = {
  environment: {
    production: true,
  },
  appVersion: require('../../package.json').version + '-dev',
  isDebugMode: true,
  //API_ENDPOINT: 'http://64.76.16.187:8180/wholesale',
  API_ENDPOINT: 'https://uat.mobilize-wholesale.com.ar/api',
  SITE_KEY: '6Led888nAAAAALS6WAZk-CddoCqD4XzGziDMkUCZ'
};


export const publicUrls: any[] = [
  `${ENV.API_ENDPOINT}/login`,
  `${ENV.API_ENDPOINT}/user/validarToken`,
  `${ENV.API_ENDPOINT}/user/restablecerPassword`,
  `${ENV.API_ENDPOINT}/user/recuperarPassword`,
  `${ENV.API_ENDPOINT}/reclamos/nuevo`,
  `${ENV.API_ENDPOINT}/archivo/obtenerArchivos/publicos`,
  `${ENV.API_ENDPOINT}/archivo/descargaArchivo`
];

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConcesionarioFiltroPipe } from './concesionario-filtro.pipe';
import { UsuariosPipe } from './usuarios.pipe';

@NgModule({
  declarations: [	ConcesionarioFiltroPipe,
      UsuariosPipe
   ],
  imports: [
    CommonModule
  ],
  
  exports: [ConcesionarioFiltroPipe,UsuariosPipe]
})
export class PipesModule { }

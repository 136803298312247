import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

/**
 * @returns ValidatorFn para validar solo letras con espacios en blanco
 */
export const LETRAS_CON_ESPACIOS: ValidatorFn = Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ \s]*$');

/**
 * @returns ValidatorFn para validar letras con tildes, signos de puntuacion y comas, espacios en blanco y numeros
 */
export const LETRAS_NUMEROS_TILDES: ValidatorFn = Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ.,0-9 \s]*$");

/**
 * @returns ValidatorFn para validar unicamente letras, numeros, con espacios en blanco y sin caracteres especiales.
 */
export const LETRAS_NUMEROS_CON_ESPACIOS: ValidatorFn = Validators.pattern('^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑüÜ \s]*$');

/**
 * @returns ValidatorFn para validar unicamente letras, numeros, sin espacios en blanco y sin caracteres especiales.
 */
export const LETRAS_NUMEROS_SIN_ESPACIOS: ValidatorFn = Validators.pattern('^[a-zA-Z0-9]*$');

/**
 * @returns ValidatorFn para validar solo numeros enteros.
 */
export const SOLO_NUMEROS: ValidatorFn = Validators.pattern('^[0-9]*$');

/**
 * @returns ValidatorFn para validar números enteros y decimales, pero no caracteres especiales adicionales.
 */
export const NUMEROS_DECIMALES: ValidatorFn = Validators.pattern('^[0-9]*\.?[0-9]*$');

/**
 * @returns ValidatorFn para validar el formato de email.
 */
export const EMAIL: ValidatorFn = Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$");

export function onlyNumberInput(event: any) {
    const pattern = /[0-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
}

export function optionalValidator(validators?: (ValidatorFn | null | undefined)[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {

      return control.value ? Validators.compose(validators)(control) : null;
  };
}

export function passwordsIguales( pass1Name: string, pass2Name: string ) {

  return ( formGroup: FormGroup ) => {

    const pass1Control = formGroup.controls[pass1Name];
    const pass2Control = formGroup.controls[pass2Name];

    if ( pass1Control.value === pass2Control.value ) {
      pass2Control.setErrors(null);
    } else {
      pass2Control.setErrors({ noEsIgual: true });
    }

  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

    public setItem(key: string, value: any) {
        return localStorage.setItem(key, JSON.stringify(value));
    }

    public getItem(key: string) {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }

    public removeItem(key: string) {
        return localStorage.removeItem(key);
    }
}

<div class="banner">
  <img src="assets/img/bannerDocumentacion.jpg" alt="" />
</div>
<div class="container flex-column">
  <div class="title">
    <h1 class="bold">documentación relacionada</h1>
  </div>

  <div class="seccion-body">
    <div class="mb-2">
      <h6 class="bold">Descarga de archivos</h6>
    </div>
    <div class="table-content">
      <table class="table table-hover align-middle">
        <thead>
          <tr>
            <th scope="col">ARCHIVO</th>
            <th class="col-3">DESCARGA</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let archivo of archivos">
            <td>{{archivo.nombre}}</td>
            <td class="col-3">
              <button class="bg-transparent border-0" (click)="getDownloadUrl(archivo)">
                <i class="icon icon-download"></i> PDF
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { LoaderService } from "../loader/loader-services.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(private loader: LoaderService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        this.loader.showLoader();
        return next.handle(req).pipe(
          finalize(() => this.loader.hideLoader())
        );
      }
}
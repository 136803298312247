import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DocumentacionComponent } from './pages/documentacion/documentacion.component';
import { ReclamosComponent } from './pages/reclamos/reclamos.component';

const routes: Routes = [
  {path: '', component:HomeComponent},
  {path: 'documentacion', component:DocumentacionComponent},
  {path: 'reclamos', component:ReclamosComponent},  
  { path: 'espacio-admin', loadChildren: () => import('./pages/espacio-admin/espacio-admin.module').then(m => m.EspacioAdminModule) },
];

const extraOptions: ExtraOptions = {
  "enableTracing": false,
  "useHash": true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public id!: number;

  constructor(private router: Router,
    private authService: AuthService
    ) {}

  public setToken(token: string): void {

    localStorage.setItem('token',token);
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public logOut(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public isLoggedIn(): boolean {
    return this.getToken() != null;
  }


  buscarUsuario(): void{
    const values = this.getValues();
    this.id= values.id;
    this.authService.usuario(this.id);
  }

  public notExpired(): boolean {

    const values = this.getValues();
    const expiracion = values.exp;
    const ahora = Date.now() / 1000;
    const diferencia = expiracion - ahora;

    if (diferencia > 0) {
      return true;
    } else {
      return false;
    }
  }


  getTipo(): string {
    const values = this.getValues();
    return values.tipo;
  }

  getValues(): any{
    const token = this.getToken();
    const payload = token!.split('.')[1];
    const payloadDecoded = atob(payload);
    const values = JSON.parse(payloadDecoded);

    return values;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENV } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {

  private url= ENV.API_ENDPOINT;


  constructor(private http: HttpClient) {
  }
  
  
  public enviarArchivo( archivo: any): Observable<any> {
    return this.http.post<any>( `${this.url}` + '/archivo/alta', archivo);
  }
  
  public consultarArchivosPublico(): Observable<any> {
    return this.http.get<any>( `${this.url}` + '/archivo/obtenerArchivos/publicos');
  }

  public consultarArchivosAdmin(): Observable<any> {
    return this.http.get<any>( `${this.url}` + '/archivo/obtenerArchivos');
  }

  public eliminarArchivo(id: any): Observable<any> {
    return this.http.get<any>( `${this.url}/archivo/baja?id=${id}`);
  }

  public getDownloadUrl(solicitud: any): Observable<any> {
    return this.http.post(`${this.url}/archivo/descargaArchivo`, solicitud)

  }

  public getDownloadUrlConcesionario(id: any): Observable<any> {
    return this.http.get(`${this.url}/archivo/descargaArchivo?id=${id}`);

  }

  public filtrarArchivoConcesionario( filtro: any): Observable<any> {
    return this.http.post<any>( `${this.url}` + '/archivo/archivosConcesionario/filtrar', filtro);
  }

  public concesionarios(): Observable<any> {
    return this.http.get<any>( `${this.url}/concesionarios`);
  }
  public concesionario(id: any): Observable<any> {
    return this.http.get<any>( `${this.url}/concesionarios/porId?id=${id}`);
  }

  public estadoArchivoConcesionario( id: number, estado: string): Observable<any> {
    return this.http.get<any>( `${this.url}/archivo/archivosConcesionario/estado?id=${id}&estado=${estado}` );
  }
}

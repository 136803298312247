import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENV } from '@env';
import { StorageService } from '../storge/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReclamoService {

  private url= ENV.API_ENDPOINT;


  constructor(private http: HttpClient) {
  }
  
  
  public enviarReclamo( reclamo: any): Observable<any> {
    return this.http.post<any>( `${this.url}` + '/reclamos/nuevo', reclamo);
  }


  public consultarReclamos(): Observable<any>{
    return this.http.get<any>( `${this.url}` + '/reclamos/todos');
  }
}

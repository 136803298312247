import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usuariosFiltro'
})
export class UsuariosPipe implements PipeTransform {
  lista=[];

  transform(usuarios:any[], arg: any): any {

    

    if(arg === ''){
      usuarios.sort(function(a,b) {
        return b.id - a.id;
      });
    }

      let resultado= usuarios.filter( usuario => usuario.nombre.toLowerCase().includes(arg.toLowerCase()) 
      ||  usuario.apellido.toLowerCase().includes(arg.toLowerCase()) );     
  
      return resultado







  }


}

<section class="container">
  <div class="row" style="width: 88%;">
      <div class="col-md-12">
          <div class="content  my-5">
              <h5 class="bold mb-1">acceso al gestor de archivos</h5>
              <div class="content">
                  <div class="row ">

                      <!-- TABS NAVS -->
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item">
                              <a (click)="this.isDetallesEndososActive = false" class="nav-link active" id="archivos-tab" data-bs-toggle="tab" data-bs-target="#archivos-tab-pane" type="button" role="tab" aria-controls="archivos-tab-pane" aria-selected="false">
                                  historial
                              </a>
                          </li>

                      </ul>
                      <!-- --------- -->

                      <!-- TABS CONTENT -->
                      <div class="tab-content p-0" id="myTabContent">
                        <h6 class="my-1">{{detalle.nombre}}</h6>
                          <div class="tab-pane fade show active" id="archivos-tab-pane" role="tabpanel" aria-labelledby="archivos-tab " tabindex="0">
                            <div class="content p-0 pt-1">
                                <div  class="row justify-content-end align-items-center p-0 ">
                                    <div class="col-3">
                                        <button class="btn btn-secondary btn-default d-flex justify-content-evenly align-items-center" (click)="activarModal()">
                                            <i class="icon-more icon-sm"></i>
                                            cargar archivo
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="content p-0 pb-2 ">
                                <table class="table border-1 border-bottom border-dark">
                                    <thead class="bold">
                                        <tr >
                                            <th scope="row" class="text-lowercase light col-2 py-1-5">fecha alta</th>
                                            <th scope="row" class="text-lowercase light col-2 py-1-5">nombre</th>
                                            <th scope="row" class="text-lowercase light col-1 py-1-5">estado</th>
                                            <th scope="row" class="text-lowercase  light col-2 py-1-5">usuario</th>
                                            <th scope="row" class="text-lowercase light col-1 py-1-5">descargar</th>
                                            <th scope="row" class="text-lowercase light col-6 py-1-5">comentarios</th>
                                          </tr>
                                      </thead>
                                      <tbody class="text-uppercase body-text-xs light table-group-divider">
                                          <tr *ngFor="let archivo of archivos"  class="align-star" >
                                              <td class="text-uppercase  ">{{archivo.fechaCreacion| date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                              <td class="text-uppercase"><div class="short-text" (click)="activarModalNombre(archivo.nombre)">
                                                {{archivo.nombre}} 
                                            </div></td>
                                              <td class="text-uppercase" [ngClass]="{'rechazado' : archivo.estado === 'RECHAZADO' , 'aprobado' : archivo.estado === 'APROBADO' }">{{archivo.estado}}</td>
                                              <td class="text-uppercase  ">{{archivo.userCrea}}</td>
                                              <td>
                                                <button class="bg-transparent border-0" (click)="getDownloadUrl(archivo)">
                                                  <i class="icon icon-download"></i>
                                                </button>
                                              </td>
                                              <td class="text-uppercase">
                                                <div class="short-text" (click)="activarModalNombre(archivo.comentario)">
                                                    {{archivo.comentario}} 
                                                </div>
                                                    
                                            </td>
                                          </tr>
                                          
                                      </tbody>
                                </table>
                                <div  *ngIf="archivosNuevos === false">
                                    <p class="h6 light d-flex justify-content-center">No se encuentran archivos para mostrar</p>
                                </div>
                              </div>
                              <!-- ----------------- -->

                          </div>
                          
                      </div>
                      <!-- ------------ -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- ----------------- -->


<div *ngIf="ModalActivate">
  <div class="modal-carga" >
    <div class="cookies-personalizar-content">
      <div class="cookies-personalizar-header">
        <div class="titulo"><h4 class="bold">Cargar archivo</h4></div>
        <button class="btn-cerrar h-2 bold" (click)="activarModal()">X</button>
      </div>
      <div class="body">
          <div class="modal-form form-content">
              <form [formGroup]="formArchivo" >
                  <div class="form-row">
                      <div class="form-group">
                          <div class="form-floating">
                              <input formControlName="ctrlNombre" name="nombre" type="text" class="form-control" placeholder="name@example.com" autocomplete="off">
                              <label>nombre del archivo</label>
                              <div *ngIf="formArchivo.get('ctrlNombre').touched && formArchivo.get('ctrlNombre').errors?.required" class="text-start" >
                                  <p class="color-error"> El campo es requerido.</p>
                              </div>
                          </div>
                      </div>
                      <div >
                        <div  class="text-start" >
                            <p class="body-text-xs">Solo se aceptan archivos docx, doc, xlsx, xls o pdf. Máx 50mb</p>
                        </div>

                        <!--<div class="input-group mb-3">
                            <input (change)="onFileSelected($event)" type="file" class="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                          </div>-->

                        <input (change)="onFileSelected($event)" data-file-type="ctrlArchivo" class="file" type="file" id="archivo" >
                          <label for="archivo" class="file">
                            <span class="file_name">{{filename}}</span>
                            <span class="file_button">seleccionar archivo</span>
                          </label>
                          <div *ngIf="errorMsgActive">
                            <p class="color-error">{{errorMsg}}</p>
                        </div>
                      </div>
                      <div class="form-group mb-1-5 ">
                        
                        
                        <div class="form-floating">
                            <textarea formControlName="ctrlComentario" [maxlength]="500" name="nombre" type="text" class="form-control textarea" placeholder="name@example.com" autocomplete="off" (keyup)="contadorCaractes()"></textarea>
                            <label>agregar comentarios</label>
                            <div *ngIf="formArchivo.get('ctrlComentario').touched && formArchivo.get('ctrlComentario').errors?.required" class="text-start" >
                                <p class="color-error"> El campo es requerido.</p>
                            </div>
                            <p class="body-text-xs" [ngClass]="{'error' : caracteres >= 490}" >{{caracteres}}/500</p>
                        </div>
                    </div>
                      <div class="form-group m-0-5">
                          <button type="submit" class="btn btn-primary btn-default" [disabled]="formArchivo.invalid" (click)="enviarArchivo()"> enviar </button>
                      </div>
                  </div>
              </form>
          </div>
      </div>


      
    </div>
  </div>
</div>


<div *ngIf="ModalResponseActivate">
  <div class="modal-response" >
      <div class="cookies-personalizar-content">
          <div class="cookies-personalizar-header">
              <div class="titulo">
                  <h5 class="bold"></h5>
              </div>
              <button class="btn-cerrar h-2 bold" (click)="activarModalResponse()">X</button>
          </div>
          <div class="body">
              <div class="modal-form form-content mx-3 mb-2">
                  <h6>{{respEstado}}</h6>
              </div>
              <div class="modal-form form-content mx-3">

                  <p class="body-text-m">{{respMensaje}}</p>
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngIf="ModalNombreActivate">
    <div class="modal-response" >
        <div class="cookies-personalizar-content">
            <div class="cookies-personalizar-header">
                <div class="titulo">
                    <h5 class="bold"></h5>
                </div>
                <button class="btn-cerrar h-2 bold" (click)="ModalNombreActivate= false">X</button>
            </div>
            <div class="body">
                <div class="modal-form form-content mx-3 p-1" >
  
                    <p class="body-text-m">{{ModalNombreMensaje}}</p>
                </div>
            </div>
        </div>
    </div>
  </div>

<div *ngIf="ModalConfirmacion">

  <div class="modal-response" >
      <div class="cookies-personalizar-content">
        <div class="cookies-personalizar-header">
          <div class="titulo"><h5 class="bold">Eliminar Archivo</h5></div>
          <button type="button" class="close-btn" aria-label="Close" (click)="action(false)">
              <i class="icon-close icon-sm"></i>
          </button>
        </div>
        <div class="body">
            <div class="modal-form form-content mx-3 mt-3">
              <div class="row justify-content-center">
                  <div >
                      <div class="modal-form form-content mb-2">
                          <h6>Desea Eliminar el archivo?</h6>
                      </div>
                  </div>
              </div>
                
            </div>
            <div class="modal-footer">
              <button type="button" (click)="action(false)" class="btn btn-default btn-primary">
                  NO
              </button>
  
              <button type="button" (click)="action(true)" class="btn btn-default btn-secondary">
                  SI
              </button>
          </div>
        </div>
      </div>
  </div>
</div>
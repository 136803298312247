import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concesionarioFiltro'
})
export class ConcesionarioFiltroPipe implements PipeTransform {

  transform(concesionarios:any[], arg: any): any {

    if(arg === ''){
      return concesionarios;
    }

    const resultado = concesionarios.filter( concesionario => concesionario.nombre.toLowerCase().includes(arg.toLowerCase()) || concesionario.id.toLowerCase().includes(arg.toLowerCase()) ) 
    
    return resultado;


  }

}

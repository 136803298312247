<div class="banner">
  <img src="assets/img/bannerHome.jpg" alt="" />
</div>
<div class="container">
  <div class="title">
    <h1 class="title-bolder">Mobilize Wholesale</h1>
    <p class="body-text-l text-center">
      Otorgamos créditos a la red de Concesionarios Renault y Nissan para
      financiar la compra de vehículos y repuestos las marcas.
      <br> Para ello,
      contamos con productos crediticios especialmente diseñados.
    </p>
  </div>
</div>



<div class="seccion-tabs">
  <div class="body">
    <div class="content">
      <div class="text text-end">
        <div>
          <p class="h5 bold">reclamos</p>
          <p class="body-text-m info">
            A través del formulario de reclamos, en el caso de que necesites
            dejarnos tu consulta para que la gestionemos, te invitamos a
            dejarnos tus datos para que podamos iniciarlo.
          </p>
        </div>
        <div>
          <button class="btn btn-primary text-uppercase" [routerLink]="['/reclamos']"> VER MÁS</button>
        </div>
      </div>
      <div class="img">
        <img src="assets/img/reclamosHome.jpg" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="img">
        <img src="assets/img/documentacionHome.jpg" alt="" />
      </div>
      <div class="text text-start">
        <div >
          <p class="h5 bold">documentación relacionada</p>
          <p class="body-text-m info">
            Encontrá acá toda la documentación con información que necesites
            leer o descargar.
          </p>
        </div>
        <div>
          <button class="btn btn-primary text-uppercase" [routerLink]="['/documentacion']"> VER MÁS</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="seccion-info">
  <div class="content">
    <div>
      <img class="info-img" src="assets/img/fotoAndrea.jpg" alt="" />
    </div>
    <div class="info-body">
      <p class="h2">
        <span class="body-text-s bold color-principal p-0"
          >RCI BANQUE S.A.</span
        >
        <br />
        <span class="bold">Andrea Arrosi</span>
        <br />directora general
      </p>
    </div>
  </div>
</div>

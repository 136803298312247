import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { DocumentacionComponent } from './pages/documentacion/documentacion.component';
import { ReclamosComponent } from './pages/reclamos/reclamos.component';
import { ComponentsModule } from './components/components.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EspacioAdminModule } from './pages/espacio-admin/espacio-admin.module';
import { InterceptorsService } from './services/interceptors/interceptors.service';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ENV } from '@env';
import { LoaderInterceptor } from './services/interceptors/loader-interceptor.service';
import { DirectivesModule } from './directives/directives.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DocumentacionComponent,
    ReclamosComponent
  ],
  imports: [
    BrowserModule,
    EspacioAdminModule,
    RecaptchaV3Module,
    HttpClientModule,
    ComponentsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DirectivesModule,
    FormsModule,
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: ENV.SITE_KEY,
    }
  ]
,
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataNotificationDTO } from '@app/models/user.model';
import { ENV } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = ENV.API_ENDPOINT;

  constructor(private http: HttpClient) {}

  public filtrarUsuarios(filtro: any): Observable<any> {
    return this.http.post<any>(`${this.url}` + '/user/filtrar', filtro);
  }

  public crearUsuarios(data: any): Observable<any> {
    return this.http.post<any>(`${this.url}` + '/user/alta', data);
  }

  public bajaUsuarios(data: any): Observable<any> {
    return this.http.post<any>(`${this.url}` + '/user/baja', data);
  }

  public modificarUsuario(data: any): Observable<any> {
    return this.http.post<any>(`${this.url}` + '/user/modificar', data);
  }

  public validarToken(token: any):Observable<any> {
    return this.http.get<any>( `${this.url}/user/validarToken`, { headers: new HttpHeaders({'Authorization': 'Bearer ' + token})} );
  }


  public changePassword(CambioPasswordDTO: any, token: any):Observable<any> {
    return this.http.post<any>( `${this.url}/user/restablecerPassword`, CambioPasswordDTO, { headers: new HttpHeaders({'Authorization': 'Bearer ' + token})} );
  }

  public activarPassword(email: any, accion: any):Observable<any> {
    return this.http.get<any>( `${this.url}/user/activarPassword?email=${email}&accion=${accion}` );
  }

  public recuperarPassword(email: any):Observable<any> {
    return this.http.get<any>( `${this.url}/user/recuperarPassword?email=${email}` );
  }
  
  public notificacionMasiva(data: DataNotificationDTO):Observable<any> {
    return this.http.post<any>( `${this.url}/concesionarios/notificar`, data);
  }

}

import { Component, Injectable, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArchivoDTO } from '@app/models/archivo.model';
import {
  ReclamoResponseDTO,
} from '@app/models/response.model';
import { DataNotificationDTO, User, detalleEX, detalleIN } from '@app/models/user.model';
import { ArchivosService } from '@app/services/archivos/archivos.service';
import { AuthService } from '@app/services/auth/auth.service';
import { ReclamoService } from '@app/services/reclamos/reclamo.service';
import { UserService } from '@app/services/user/user.service';
import { EMAIL } from '@app/services/utils/utils.services';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileReaderService {
  public async readAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  }
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

  @ViewChild('myInput') myInput: ElementRef;

  private subscriptions: Subscription = new Subscription();

  public currentUserDetalle: detalleIN ;
  formData: FormData = new FormData();
  public reclamos: ReclamoResponseDTO[];
  public reclamosnuevos = false;
  public archivosNuevos = false;
  public archivos: ArchivoDTO[] = [];

  public ModalActivate: boolean = false;
  public ModalUserActivate: boolean = false;
  public ModalResponseActivate: boolean = false;
  public ModalConfirmacion: boolean = false;
  public ModalMail: boolean = false;

  public loading: boolean = true;
  public isShowForm: boolean = false;
  public errorMsgActive: boolean = false;
  public errorMsg: String = '';

  public nombreFiltro = '';
  public usuarioFitro= '';
  public archivoEliminar!: ArchivoDTO;

  public usuarioAccion: User;

  public formArchivo: FormGroup;
  public formUser: FormGroup;
  public formNotificacion: FormGroup;

  // OTROS
  public switchOpcion: any;
  public gestionesMensaje: string;
  respEstado: string;
  respMensaje: any;
  marcas: any[] = [];
  marcaSeleccionada: string;
  disableConcesionarios: boolean = true;
  concesionarios: any[] = [];
  conDestinatarios: any[] = [];
  conSeleccionados: any[] = [];
  concesionarioSeleccionado: string = '';
  destinatariosNombres: string[] = [];
  usuarios: [] = [];
  tipoInterno: boolean= false;
  tipoExterno: boolean= false;
  ModalConfirmacionAccionUsuario: boolean;
  modalAccionTitulo: string;
  modalAccionPregunta: string;
  modalAccion: string;

  constructor(
    public fbuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private archivoService: ArchivosService,
    private fileReaderService: FileReaderService,
    private reclamoService: ReclamoService
  ) {
    this.formArchivo = this.fbuilder.group({
      ctrlNombre: ['', Validators.required],
      ctrlArchivo: [{}, Validators.required],
    });

    this.formUser = this.fbuilder.group({
      id: '',
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      apellido: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, EMAIL]],
      tipo: ['', Validators.required],
      detalle: ['', Validators.required],
    });

    this.formNotificacion= this.fbuilder.group({
      mensaje: ['', [Validators.required, Validators.maxLength(500)]],
      grupo: ['Todos', [Validators.required]],
      concesionarios:[],
      asunto: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.conusltarDatos();
    setTimeout(() => {
      this.currentUserDetalle= new detalleIN(this.authService.currentUser.detalle);
    }, 700)
  }

  activarModalUser() {
    this.resetUserForm();
    this.formUser.get('detalle').enable();

    this.ModalUserActivate = !this.ModalUserActivate;
  }

  activarModal() {
    this.ModalActivate = !this.ModalActivate;
  }

  activarModalResponse() {
    this.ModalResponseActivate = !this.ModalResponseActivate;
  }

  // FUNCION PARA MANEJO DE ARCHIVOS
  public async onFileSelected(event: any): Promise<void> {
    this.errorMsgActive = false;
    const file: File = event.target.files[0];
    if (file) {
      const size: number = file.size / 1024;

      if (file.type != 'application/pdf' || size > 10000) {
        this.errorMsgActive = true;
        this.errorMsg = 'El tamaños del archivo no puede ser mayor a 10mb';
        return;
      } else {
        const base64 = await this.fileReaderService.readAsDataURL(file);
        const archivo = {
          tipoArchivo: file.type,
          volumenArchivo: (file.size / 1024).toFixed(),
          nombreArchivo: file.name,
          base64: base64,
        };
        this.formArchivo.get('ctrlArchivo').setValue(archivo);
      }
    }
  }

  resetUserForm(){
    this.formUser.reset();
  }

  enviarArchivo() {
    const data = {
      nombre: this.formArchivo.get('ctrlNombre').value,
      archivo: this.formArchivo.get('ctrlArchivo').value,
      tipo: 'PU',
    };
    this.subscriptions.add(
      this.archivoService.enviarArchivo(data).subscribe((resp) => {
        if (resp.codigoRespuesta == '0') {
          this.ModalActivate = false;
          this.ModalResponseActivate = true;
          this.respEstado = 'Solicitud Exitosa';
          this.respMensaje = resp.mensaje;
          this.conusltarDatos();
        } else {
          this.ModalResponseActivate = true;
          this.respEstado = 'Error en la solicitud';
          this.respMensaje = resp.mensaje;
        }
      })
    );
  }

  conusltarDatos() {
    this.subscriptions.add(
      this.reclamoService.consultarReclamos().subscribe((resp) => {
        if (resp.codigoRespuesta == '1') {
          this.reclamos = resp.data;

          if (this.reclamos.length > 0) {
            this.reclamosnuevos = true;
          }
        } else {
          console.log(resp.mensaje);
        }
      })
    );

    this.subscriptions.add(
      this.archivoService.consultarArchivosAdmin().subscribe((resp) => {
        if (resp.codigoRespuesta == '0') {
          this.archivos = resp.data;
          this.archivosNuevos = true;
        } else {
          console.log(resp.mensaje);
        }
      })
    );
  }

  eliminarArchivo(archivo: ArchivoDTO) {
    this.archivoEliminar = archivo;

    this.ModalConfirmacion = true;
  }

  action(confirmacion: boolean) {
    if (confirmacion) {
      this.subscriptions.add(
        this.archivoService
          .eliminarArchivo(btoa(this.archivoEliminar.id))
          .subscribe((resp) => {
            if (resp.codigoRespuesta == '0') {
              this.ModalResponseActivate = true;
              this.respEstado = 'Solicitud Exitosa';
              this.respMensaje = resp.mensaje;
              this.conusltarDatos();
            } else {
              this.ModalResponseActivate = true;
              this.respEstado = 'Error en la solicitud';
              this.respMensaje = resp.mensaje;
            }
            this.ModalConfirmacion = false;
          })
      );
    } else {
      this.ModalConfirmacion = false;
    }
  }

  consultarConcesionarios() {
    this.subscriptions.add(
      this.archivoService.concesionarios().subscribe((resp) => {
        this.concesionarios = resp.data;
        this.conDestinatarios = this.concesionarios;
      })
    );
  }


    // FUNCION PARA MANEJO DE USUARIOS
  consultarUsuarios() {
    const data = {
      id: null,
      email: '',
      nombre: '',
      apellido: '',
      tipo: '',
      detale: '',
      activo: null,
      page: 0,
      size: 0,
    };

    this.subscriptions.add(
      this.userService.filtrarUsuarios(data).subscribe((resp) => {
        this.usuarios = resp.data;


    console.log(this.usuarios);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe;
  }

  selecTipo( value: any){

    if(value === 'IN'){
      this.tipoInterno = true;
      this.tipoExterno = false;
      this.formUser.get('detalle').enable();
    }

    if(value === 'EX'){
      this.consultarConcesionarios();
      this.tipoInterno = false;
      this.tipoExterno = true;

    }
  }

  enviarUsuario(){

    if(this.formUser.get('id').value === '' || this.formUser.get('id').value ===null){
      const data = {
        id: null,
        email: this.formUser.get('email').value,
        nombre: this.formUser.get('nombre').value,
        apellido: this.formUser.get('apellido').value,
        tipo: this.formUser.get('tipo').value,
        detalle: this.formUser.get('detalle').value
      };

      this.subscriptions.add(
        this.userService.crearUsuarios(data).subscribe((resp) => {
          if (resp.codigoRespuesta == '0') {
            this.ModalUserActivate= false;
            this.ModalResponseActivate = true;
            this.respEstado = 'Solicitud Exitosa';
            this.respMensaje = resp.mensaje;
            this.consultarUsuarios();

          } else {
            this.ModalResponseActivate = true;
            this.respEstado = 'Error en la solicitud';
            this.respMensaje = resp.mensaje;
          }
        })
      );

    }else{

      const data = {
        id: this.formUser.get('id').value,
        email: this.formUser.get('email').value,
        nombre: this.formUser.get('nombre').value,
        apellido: this.formUser.get('apellido').value,
        tipo: this.formUser.get('tipo').value,
        detalle:this.formUser.get('detalle').value
      };

      this.subscriptions.add(
        this.userService.modificarUsuario(data).subscribe((resp) => {
          if (resp.codigoRespuesta == '0') {
            this.ModalUserActivate= false;
            this.ModalResponseActivate = true;
            this.respEstado = 'Solicitud Exitosa';
            this.respMensaje = resp.mensaje;
            this.consultarUsuarios();

          } else {
            this.ModalResponseActivate = true;
            this.respEstado = 'Error en la solicitud';
            this.respMensaje = resp.mensaje;
          }
        })
      );
    }

  }

  confirmacionAccionUsuario(usuario : User, accion: string){

    this.usuarioAccion= usuario;
    const detalle = this.usuarioAccion.detalle;

    if(accion === 'eliminar'){
      this.ModalConfirmacionAccionUsuario= true;
      this.modalAccionTitulo = 'Desactivar usuario'
      this.modalAccionPregunta = 'Desea Desactivar el usuario?'
      this.modalAccion= accion
    }

    if(accion === 'editar'){
      this.ModalUserActivate= true;
      this.formUser.patchValue(this.usuarioAccion);

      if(this.usuarioAccion.tipo === 'IN'){
        const detalle = new detalleIN(this.usuarioAccion.detalle);
        this.formUser.get('detalle').setValue(detalle.cod);
        this.formUser.get('detalle').enable();

        this.tipoExterno=false;
        this.tipoInterno=true;
      }

      if(this.usuarioAccion.tipo === 'EX'){
        this.consultarConcesionarios();
        const detalle = new detalleEX(this.usuarioAccion.detalle);
        this.formUser.get('detalle').setValue(detalle.id.toString());

        this.tipoExterno=true;
        this.tipoInterno= false;
      }
    }

    if(accion === 'activar'){
      this.ModalConfirmacionAccionUsuario= true;
      this.modalAccionTitulo = 'Activación usuario'
      this.modalAccionPregunta = 'Desea reenviar el mail de activación al usuario?'
      this.modalAccion= accion
    }

    if(accion === 'reactivar'){
      this.ModalConfirmacionAccionUsuario= true;
      this.modalAccionTitulo = 'Activar usuario'
      this.modalAccionPregunta = 'Desea Activar el usuario?'
      this.modalAccion= accion
    }

    if(accion === 'password'){
      this.ModalConfirmacionAccionUsuario= true;
      this.modalAccionTitulo = 'Cambio de contraseña'
      this.modalAccionPregunta = 'Desea enviar el mail para el cambio de contraseña?'
      this.modalAccion= accion
    }

  }

  eliminarUsuario(){
    const data = {
      id: this.usuarioAccion.id,
      email: this.usuarioAccion.email,
      nombre: this.usuarioAccion.nombre,
      apellido: this.usuarioAccion.apellido,
      tipo: this.usuarioAccion.tipo,
      detalle: '',
      };

    this.subscriptions.add(
      this.userService.bajaUsuarios(data).subscribe((resp) => {
        if (resp.codigoRespuesta == '0') {
          this.ModalConfirmacionAccionUsuario= false;
          this.ModalResponseActivate = true;
          this.respEstado = 'Solicitud Exitosa';
          this.respMensaje = 'Usuario desactivado';
          this.consultarUsuarios();

        } else {
          this.ModalResponseActivate = true;
          this.respEstado = 'Error en la solicitud';
          this.respMensaje = resp.mensaje;
        }
      }, err => {
        this.ModalResponseActivate = true;
        this.respEstado = 'Error en la solicitud';
        this.respMensaje =' No informado';
      })
    );
  }

  enviarMailActivacion(accion: string){
    this.subscriptions.add(this.userService.activarPassword(this.usuarioAccion.email,accion ).subscribe(
      (resp) => {
        if (resp.codigoRespuesta == '0') {
          this.ModalConfirmacionAccionUsuario= false;
          this.ModalResponseActivate = true;
          this.respEstado = 'Solicitud Exitosa';
          this.respMensaje = resp.mensaje;
          this.consultarUsuarios();

        } else {
          this.ModalConfirmacionAccionUsuario= false;
          this.ModalResponseActivate = true;
          this.respEstado = 'Error en la solicitud';
          this.respMensaje = resp.mensaje;
        }
      }, err => {
        this.ModalResponseActivate = true;
        this.respEstado = 'Error en la solicitud';
        this.respMensaje =' No informado';
      }))
  }


  mostrarModalMail(){
    this.consultarConcesionarios();
    this.marcas = [...new Set(this.concesionarios.map(concesionario => concesionario.marca))];
    this.marcas.push('R-Minuto');
    this.marcas.push('Todos');
    this.ModalMail= true;
  }

  setTerminal(marca:any){
    this.conSeleccionados = [];
    if (this.marcaSeleccionada === marca) {
      this.marcaSeleccionada = null;
      this.disableConcesionarios = true;
    } else {
      this.marcaSeleccionada = marca;
      this.formNotificacion.get('grupo')?.setValue(marca);
      if(marca === 'Todos'){
        this.conDestinatarios = this.concesionarios;
      }else{
        this.conDestinatarios = this.concesionarios.filter(concesionario => concesionario.marca === marca);
      }
      const todos= {
        id:'',
        nombre: 'Todos',
        marca: this.marcaSeleccionada
      }

      this.conDestinatarios.push(todos);
      this.disableConcesionarios = false;
    }

  }

  selectDestinatario(destinatario: any) {

    if(destinatario !== ''){
      this.concesionarioSeleccionado = destinatario;
      if(this.concesionarioSeleccionado === 'TODOS'){
        this.conSeleccionados = [{id: '', nombre: 'TODOS'}];
      }else{
        let destinatarioIndex = this.conDestinatarios.findIndex(con => con.nombre === destinatario);
        let dest = this.conDestinatarios[destinatarioIndex];
        if(!this.conSeleccionados.includes(dest)){
          this.conSeleccionados.push(this.conDestinatarios[destinatarioIndex]);
        }
        this.myInput.nativeElement.value = '';
      }
    }
  }

  quitarDestinatario(destinatarioIndex: any){
    this.conSeleccionados.splice(destinatarioIndex, 1);
    this.concesionarioSeleccionado = '';
  }

  enviarNotificacionMasiva(){
    let texto: String= this.formNotificacion.get('mensaje').value;
    let mensaje = texto.replaceAll('\n', '<br/>')
    const data: DataNotificationDTO= {
      userSender: this.authService.currentUser.email,
      grupo: this.formNotificacion.get('grupo').value,
      concesionarios: this.conSeleccionados.map(concesionario => concesionario.id),
      asunto: this.formNotificacion.get('asunto').value,
      mensaje: mensaje
    }
    if(this.concesionarioSeleccionado === 'TODOS'){
      data.concesionarios = [];
    }

    this.subscriptions.add(this.userService.notificacionMasiva(data).subscribe(resp => {
      if (resp.codigoRespuesta == '0') {
        this.ModalMail= false;
        this.ModalResponseActivate = true;
        this.respEstado = 'Solicitud Exitosa';
        this.respMensaje = resp.mensaje;
      } else {
        this.ModalMail= false;
        this.ModalResponseActivate = true;
        this.respEstado = 'Error al procesar la solicitud';
        this.respMensaje = resp.mensaje;
      }
    }, (error)=> {
      this.ModalMail= false;
      this.ModalResponseActivate = true;
      this.respEstado = 'Error al procesar la solicitud';
    }));
    this.formNotificacion.reset();
    this.conSeleccionados = [];
  }

}

export class UserModel {
    email: string;
    password: string ;

    constructor(email: string, password: string) {

        this.email = email;
        this.password = password;
    }
}

export interface User{
    id: number;
    email: string;
    nombre: string;
    apellido: string;
    tipo: string;
    detalle: {};
}

export class detalleIN{
    id: number;
    cod: string;
    descript: string;
    constructor(data: any) {
        this.id = data.id;
        this.cod = data.cod;
        this.descript = data.descript;
    }
}

export class detalleEX{
    id: number;
    descript: string;
    nombre: string;
    constructor(data: any) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.descript = data.descript;
    }
}

export interface DataNotificationDTO{

    userSender: string;
    grupo: string;
    concesionarios: string[];
    asunto: string;
    mensaje: string;
}
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericResponseDTO } from '@app/models/response.model';
import { ReclamoService } from '@app/services/reclamos/reclamo.service';
import { EMAIL } from '@app/services/utils/utils.services';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-reclamos',
  templateUrl: './reclamos.component.html',
  styleUrls: ['./reclamos.component.scss']
})
export class ReclamosComponent {


  reclamoForm!: FormGroup;
  reCAPTCHAToken: string = "";
  ModalActivate= false;
  respMensaje='';
  respEstado='';

  public caracteres= 0;


  constructor(private formBuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private reclamoService:ReclamoService) {}
  ngOnInit() {
      this.reclamoForm = this.formBuilder.group({
        nombreCtrl: ['', Validators.compose([Validators.required])],
        emailCtrl: ['', Validators.compose([Validators.required, EMAIL])],
        concesionarioCtrl:[ '',Validators.compose([Validators.required ])],
        mensajeCtrl: ['', Validators.compose([Validators.required, Validators.maxLength(500)])]
      });
  }


  enviar() {

      this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
        if (token!==null) {
          this.reCAPTCHAToken = token;
          const reclamo = this.setearReclamo();

          this.reclamoService.enviarReclamo(reclamo).subscribe(( resp: GenericResponseDTO)=>{
            if(resp.codigoRespuesta == '1'){
              this.ModalActivate = true;
              this.respEstado= 'Solicitud Exitosa'
              this.respMensaje= resp.mensaje;
              this.reclamoForm.reset();
            }else{
              this.ModalActivate = true;
              this.respEstado= 'Error en la solicitud'
              this.respMensaje= resp.mensaje;
            }

          },err => {
          } )

        }else{
          console.log("no eres humano");
        }
    });

  }

  setearReclamo(): any {

    let email : string= this.reclamoForm.value.emailCtrl

    let reclamo = {
      nombre: this.reclamoForm.value.nombreCtrl,
      email: email.toLowerCase(),
      concesionario: this.reclamoForm.value.concesionarioCtrl,
      mensaje: this.reclamoForm.value.mensajeCtrl,
      tokenRecaptcha: this.reCAPTCHAToken
    }

    return reclamo;

  }

  activarModal(){

    this.ModalActivate=!this.ModalActivate;
  }

  contadorCaractes(){

    let comentario = this.reclamoForm.value.mensajeCtrl;

    this.caracteres= comentario.length;
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserModel } from '@app/models/user.model';
import { AuthService } from '@app/services/auth/auth.service';
import { TokenService } from '@app/services/auth/token.service';
import { UserService } from '@app/services/user/user.service';
import { EMAIL } from '@app/services/utils/utils.services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  usuarioLogin: any;
  usuario!: string;
  clave!: string;
  isShowAlert = false;
  ModalContrasena= false;

  public credentialKey: string = '_currentUser';
  public errorMsg: string = '';

  public email= '';
  ModalResponseActivate= false;
  respEstado: string;
  respMensaje: any;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private tokenService: TokenService,
    private userService: UserService,
    private router: Router
  ) {
    this.isActiveUser();
    this.usuarioLogin = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, EMAIL])],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  isActiveUser() {
    if(localStorage.getItem('loggedIn') == 'true'){
      
      let user=this.auth.currentUser; 

      this.redireccionar(user.tipo);
    }
    
  }

  onSubmit(data: UserModel) {
    this.isShowAlert = false;
    const dto = new UserModel(data.email, data.password);

    this.auth.login(dto).subscribe(
      (data) => {
        this.tokenService.setToken(data.token);

        const tipo = this.tokenService.getTipo();

        this.tokenService.buscarUsuario();
        this.redireccionar(tipo);
      },
      (error) => {
        this.isShowAlert = true;
      }
    );
  }

  redireccionar(tipo: string) {
    if (tipo === 'IN') {
      this.router.navigate(['/ad']);
    }

    if (tipo === 'EX') {
      this.router.navigate(['/ad/extern']);
    }
  }

  
  enviarMailActivacion(){
    this.userService.recuperarPassword(this.email).subscribe(
      (resp) => {
        if (resp.codigoRespuesta == '0') {
          this.ModalContrasena= false;
          this.ModalResponseActivate = true;
          this.respEstado = 'Solicitud Exitosa';
          this.respMensaje = resp.mensaje;

        } else {
          this.ModalContrasena= false;
          this.ModalResponseActivate = true;
          this.respEstado = 'Error en la solicitud';
          this.respMensaje = resp.mensaje;
        }
      }, err => {
        this.ModalResponseActivate = true;
        this.respEstado = 'Error en la solicitud';
        this.respMensaje =' No informado';
      })
  }
}

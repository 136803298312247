import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MostarRolDirective } from './mostar-rol.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [	MostarRolDirective ],
  exports:[	MostarRolDirective]
})
export class DirectivesModule { }

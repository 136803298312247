<div class="sub-header" [ngClass]="scroll ? 'hidden' : 'sub-header'" id="sub-header"></div>

<header class="header-container">
  <nav class="container header" [ngClass]="scroll ? 'scrollup-header' : 'header'" id="header">
    <a class="logo-container" href="#">
      <img class="logo" src="assets/img/logo.png" alt="Logo mobilize" />
    </a>

    <div *ngIf="menu">
      <div  [ngClass]="logedin ? 'menu-header-loggedIn' : 'menu-header'" id="header" >
        <div class="menu-item" >
          <a class="menu-item-link" href="#/reclamos">reclamos</a>
        </div>
        <div class="menu-item">
          <a class="menu-item-link" href="#/documentacion">documentación</a>
        </div>
        <div *ngIf="!logedin" class="menu-item">
          <a  class="menu-item-link" href="#/login">ingresar</a>
        </div>

        <div *ngIf="!this.isActiveUser()" class="menu-item" style="width: 150px;">
          <div class="d-flex justify-content-between align-items-center">
            <i class="icon-account icon-sm"></i>
              <span class="bold color-secundario" [routerLink]="['/ad']">{{ user?.nombre | lowercase }} {{ user?.apellido | lowercase }}</span>
          </div>
        </div>

        <div *ngIf="!this.isActiveUser()" class="menu-item">
          <div class="d-flex justify-content-between ">
            <button class="dropdown-item-logout" (click)="logout(true)">
            salir
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>

<section class="container">
  <div class="row" style="width: 88%;">
      <div class="col-md-12">
          <div class="content  my-5">
              <h5 class="bold mb-1">acceso al gestor de archivos</h5>
              <div class="content">
                  <div class="row ">

                      <!-- TABS NAVS -->

                      <!-- --------- -->

                      <!-- TABS CONTENT -->
                      <div class="tab-content p-0" id="myTabContent">
                          <div class="tab-pane fade show active" id="archivos-tab-pane" role="tabpanel" aria-labelledby="archivos-tab " tabindex="0">
                            <h6>{{concesionario.nombre}}</h6>
                            <div class="content p-0 py-2 my-1">
                                <table class="table border-1 border-bottom border-dark">
                                    <thead class="bold">
                                        <tr >
                                            <th scope="row" class="text-lowercase light col-2 py-1-5">fecha</th>
                                            <th scope="row" class="text-lowercase light col-2 py-1-5">nombre</th>
                                            <th scope="row" class="text-lowercase  light col-2 py-1-5">usuario</th>
                                            <th scope="row" class="text-lowercase light col-1 py-1-5">descargar</th>
                                            <th scope="row" class="text-lowercase light col-6 py-1-5">comentarios</th>
                                            <th scope="row" class="text-lowercase light col-1 py-1-5">estado</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-uppercase body-text-xs light table-group-divider">
                                        <tr *ngFor="let archivo of archivos"  class="align-star" >
                                            <td class="text-uppercase  ">{{archivo.fechaCreacion| date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                            <td class="text-uppercase  short-text">{{archivo.nombre}}</td>
                                            <td class="text-uppercase  ">{{archivo.userCrea}}</td>
                                            <td>
                                            <button class="bg-transparent border-0" (click)="getDownloadUrl(archivo)">
                                                <i class="icon icon-download"></i>
                                            </button>
                                            </td>
                                            <td class="text-uppercase  short-text">{{archivo.comentario}}</td>
                                            <td class="text-uppercase">
                                            <select class="select" [ngClass]="{'rechazado' : archivo.estado === 'RECHAZADO' , 'aprobado' : archivo.estado === 'APROBADO' }" name="" id="" ngModel="{{archivo.estado}}" (change)="cambiarEstado(archivo.id, $event.target.value)">
                                                <option style="color: black;" value="RECIBIDO">Recibido</option>
                                                <option style="color: black;" value="ANALISIS">En análisis</option>
                                                <option class="aprobado" value="APROBADO">Aprobado</option>
                                                <option class="rechazado" value="RECHAZADO">Rechazado</option>
                                            </select>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                <div  *ngIf="archivosNuevos === false">
                                    <p class="h6 light d-flex justify-content-center">No se encuentran archivos para mostrar</p>
                                </div>
                                
                                <div class="row justify-content-end align-items-center p-1 mt-2 pt-0">
                                    <button class="btn btn-primary text-uppercase" [routerLink]="['/ad']"> volver</button>
                                </div>
                            </div>
                          </div>
                          
                      </div>
                      <!-- ------------ -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<!--<div *ngIf="ModalActivate">
  <div class="modal-carga" >
    <div class="cookies-personalizar-content">
      <div class="cookies-personalizar-header">
        <div class="titulo"><h4 class="bold">Cargar archivo</h4></div>
        <button class="btn-cerrar h-2 bold" (click)="activarModal()">X</button>
      </div>
      <div class="body">
          <div class="modal-form form-content">
              <form [formGroup]="formArchivo" >
                  <div class="form-row">
                      <div class="form-group">
                          <div class="form-floating">
                              <input formControlName="ctrlNombre" name="nombre" type="text" class="form-control" placeholder="name@example.com" autocomplete="off">
                              <label class="form-label">nombre del archivo</label>
                              <div *ngIf="formArchivo.get('ctrlNombre').touched && formArchivo.get('ctrlNombre').errors?.required" class="text-start" >
                                  <p class="color-error"> El campo es requerido.</p>
                              </div>
                          </div>
                      </div>
                      <div >
                        <input (change)="onFileSelected($event)" data-file-type="ctrlArchivo" class="file" type="file" id="archivo" >
                          <label for="archivo" class="file">
                            <span class="file_name">{{filename}}</span>
                            <span class="file_button">seleccionar archivo</span>
                          </label>
                          <div *ngIf="errorMsgActive" class="text-start" >
                              <p class="color-error">{{errorMsg}}</p>
                          </div>
                      </div>
                      <div class="form-group mb-1-5 ">
                        <div class="form-floating">
                            <textarea formControlName="ctrlComentario" name="nombre" type="text" class="form-control textarea" placeholder="name@example.com" autocomplete="off"></textarea>
                            <label class="form-label">agregar comentarios</label>
                            <div *ngIf="formArchivo.get('ctrlComentario').touched && formArchivo.get('ctrlComentario').errors?.required" class="text-start" >
                                <p class="color-error"> El campo es requerido.</p>
                            </div>
                        </div>
                    </div>
                      <div class="form-group m-0-5">
                          <button type="submit" class="btn btn-primary btn-default" [disabled]="formArchivo.invalid" (click)="enviarArchivo()"> enviar </button>
                      </div>
                  </div>
              </form>
          </div>
      </div>


      
    </div>
  </div>
</div>-->


<div *ngIf="ModalResponseActivate">
  <div class="modal-response" >
      <div class="cookies-personalizar-content">
          <div class="cookies-personalizar-header">
              <div class="titulo">
                  <h5 class="bold"></h5>
              </div>
              <button class="btn-cerrar h-2 bold" (click)="activarModalResponse()">X</button>
          </div>
          <div class="body">
              <div class="modal-form form-content mx-3 mb-2">
                  <h6>{{response.status}}</h6>
              </div>
              <div class="modal-form form-content mx-3">

                  <p class="body-text-m">{{response.mensaje}}</p>
              </div>
          </div>
      </div>
  </div>
</div>

<!--<div *ngIf="ModalConfirmacion">

  <div class="modal-response" >
      <div class="cookies-personalizar-content">
        <div class="cookies-personalizar-header">
          <div class="titulo"><h5 class="bold">Eliminar Archivo</h5></div>
          <button type="button" class="close-btn" aria-label="Close" (click)="action(false)">
              <i class="icon-close icon-sm"></i>
          </button>
        </div>
        <div class="body">
            <div class="modal-form form-content mx-3 mt-3">
              <div class="row justify-content-center">
                  <div >
                      <div class="modal-form form-content mb-2">
                          <h6>Desea Eliminar el archivo?</h6>
                      </div>
                  </div>
              </div>
                
            </div>
            <div class="modal-footer">
              <button type="button" (click)="action(false)" class="btn btn-default btn-primary">
                  NO
              </button>
  
              <button type="button" (click)="action(true)" class="btn btn-default btn-secondary">
                  SI
              </button>
          </div>
        </div>
      </div>
  </div>
</div>-->
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { TokenService } from "@app/services/auth/token.service";
import { StorageService } from "@app/services/storge/storage.service";

@Injectable({
    providedIn: 'root'
  })
  export class AuthGuardGuard implements CanActivate {
    realRol!: string;
  
    constructor(
      private tokenService: TokenService,
      private storageService: StorageService,
      private router: Router
    ) {}
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean {

      if(!this.tokenService.isLoggedIn() || this.tokenService.notExpired()=== false){
        this.storageService.removeItem('token');
        this.router.navigate(['/ad']);
        return false;
      }
      
      return true;
    }
    
  }
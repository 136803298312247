import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { User, detalleIN } from '@app/models/user.model';
import { AuthService } from '@app/services/auth/auth.service';

@Directive({
  selector: '[appMostarRol]'
})
export class MostarRolDirective  {

  user: User;
  
  constructor(
    private authService: AuthService,
    private viewContainerRef: ViewContainerRef,
    private  templateRef: TemplateRef<any>
  ) { 
  }

    
  @Input() set appMostarRol(allowed: any[]){
    setTimeout(() => {
      this.user = this.authService.currentUser;
      const detalle = new detalleIN(this.user.detalle);
      for(let allow of allowed){
        
        if(allow === detalle.cod){
  
          this.viewContainerRef.createEmbeddedView(this.templateRef);
          return;
        }else{
          this.viewContainerRef.clear();
        }
      }
    }, 500);
  }
  

  
}
<div class="banner">
    <img src="assets/img/bannerReclamos.jpg" alt="" />
  </div>
<div class="container flex-column">
  <div class="title">
    <h1 class="title-bolder">reclamos</h1>
  </div>

  <div class="seccion-body">
    <div>
        <h6 class="bold"> Completá el siguiente formulario para iniciar tu reclamo</h6>
    </div>
    <div class="separador"></div>
    <div class="formulario">
        <div class="content">
            <form [formGroup]="reclamoForm" >
                <div class="mb-2">
                    <input class="form-control" type="text" formControlName="nombreCtrl" placeholder="NOMBRE Y APELLIDO">
                    <div *ngIf="reclamoForm.get('nombreCtrl').touched && reclamoForm.get('nombreCtrl').errors?.required" class="text-start" >
                      <p class="color-error"> El campo es requerido.</p>
                  </div>
                </div>
                <div class="mb-2">
                    <input class="form-control" type="email" formControlName="emailCtrl" placeholder="CORREO ELECTRÓNICO">
                    <div *ngIf="reclamoForm.get('emailCtrl').touched && reclamoForm.get('emailCtrl').errors?.required" class="text-start" >
                      <p class="color-error"> El campo es requerido.</p>
                  </div>
                  <div *ngIf="reclamoForm.get('emailCtrl').errors?.['pattern']?.requiredPattern && this.reclamoForm.get('emailCtrl').invalid" class="text-start" >
                      <p class="color-error">Debe ingresar un correo válido</p>
                  </div>
                </div>
                <div class="mb-2">
                    <input class="form-control" type="text" formControlName="concesionarioCtrl" placeholder="CONCESIONARIO">
                    <div *ngIf="reclamoForm.get('concesionarioCtrl').touched && reclamoForm.get('concesionarioCtrl').errors?.required" class="text-start" >
                      <p class="color-error"> El campo es requerido.</p>
                  </div>
                </div>
                <div class="mb-2">
                  <textarea class="form-control mensaje" rows="3" [maxlength]="500" placeholder="MENSAJE" formControlName="mensajeCtrl" autocomplete="off" (keyup)="contadorCaractes()"></textarea>
                  <div *ngIf="reclamoForm.get('mensajeCtrl').touched && reclamoForm.get('mensajeCtrl').errors?.required" class="text-start" >
                    <p class="color-error"> El campo es requerido.</p>
                  </div>
                  <p class="body-text-xs" [ngClass]="{'error' : caracteres >= 490}" >{{caracteres}}/500</p>
                </div>
                <button class="btn btn-primary" (click)="enviar()" [disabled]="reclamoForm.invalid">ENVIAR</button>
            </form>
        </div>


    </div>
  </div>

</div>

<div *ngIf="ModalActivate">
  <div class="modal-response" >
    <div class="cookies-personalizar-content">
      <div class="cookies-personalizar-header">
        <div class="titulo"><h5 class="bold"></h5></div>
        <button class="btn-cerrar h-2 bold" (click)="activarModal()">X</button>
      </div>
      <div class="body">
        <div class="modal-form form-content mx-3 mb-2">

          <h6>{{respEstado}}</h6>
      </div>
          <div class="modal-form form-content mx-3">

              <p class="body-text-m">{{respMensaje}}</p>
          </div>
      </div>



    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardGuard } from '@app/guards/authGuard.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeConcesionarioComponent } from './home-concesionario/home-concesionario.component';
import { GestorConcesionarioComponent } from './gestor-concesionario/gestor-concesionario.component';
import { PipesModule } from '@app/pipes/pipes.module';
import { DirectivesModule } from '@app/directives/directives.module';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';



@NgModule({
  declarations: [LoginComponent, HomeComponent, HomeConcesionarioComponent,GestorConcesionarioComponent,ResetPasswordComponent],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    DirectivesModule,
    RouterModule.forChild([
      {path: 'login',component: LoginComponent},
      {path: 'login/restorePassword/:id/:token',component: ResetPasswordComponent},
      {path:'ad',component: HomeComponent, canActivate: [AuthGuardGuard]},
      {path:'ad/extern',component: HomeConcesionarioComponent, canActivate: [AuthGuardGuard]},
      {path:'ad/gestor-concesionario/:id',component: GestorConcesionarioComponent, canActivate: [AuthGuardGuard]}
    ])
  ],
  exports:[LoginComponent,HomeComponent, HomeConcesionarioComponent,GestorConcesionarioComponent,ResetPasswordComponent]
})
export class EspacioAdminModule { }

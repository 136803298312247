<section class="container">
    <div class="row" style="width: 88%;">
        <div class="col-md-12">
            <div class="content  my-5">
                <h5 class="bold mb-1">acceso al gestor de archivos</h5>
                <div class="content">
                    <div class="row ">

                        <!-- TABS NAVS -->
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li *appMostarRol="['AD', 'CI', 'RI']" class="nav-item">
                                <a  class="nav-link active" id="reclamos-tab" data-bs-toggle="tab" data-bs-target="#reclamos-tab-pane" type="button" role="tab" aria-controls="reclamos-tab-pane" aria-selected="true">
                                    reclamos
                                </a>
                            </li>
                            <li *appMostarRol="['AD', 'CI', 'RI']" class="nav-item">
                                <a  class="nav-link" id="archivos-tab" data-bs-toggle="tab" data-bs-target="#archivos-tab-pane" type="button" role="tab" aria-controls="archivos-tab-pane" aria-selected="false">
                                    archivos web
                                </a>
                            </li>
                            <li *appMostarRol="['AD', 'RI']" class="nav-item">
                                <a (click)="consultarConcesionarios()" class="nav-link" id="concesionarios-tab" data-bs-toggle="tab" data-bs-target="#concesionarios-tab-pane" type="button" role="tab" aria-controls="concesionarios-tab-pane" aria-selected="false">
                                    concesionarios
                                </a>
                            </li>
                            <li *appMostarRol="['AD', 'SE']" class="nav-item">
                                <a (click)="consultarUsuarios()" class="nav-link" id="users-tab" data-bs-toggle="tab" data-bs-target="#users-tab-pane" type="button" role="tab" aria-controls="users-tab-pane" aria-selected="false">
                                    seguridad
                                </a>
                            </li>
                        </ul>
                            <!-- --------- -->

                            <!-- TABS CONTENT -->
                        <div class="tab-content p-0" id="myTabContent">

                            <div *appMostarRol="['AD', 'CI', 'RI']" class="tab-pane fade show active"  id="reclamos-tab-pane" role="tabpanel" aria-labelledby="reclamos-tab" tabindex="0">
                                <div class="content p-0 py-2 my-1">
                                    <table class="table border-1 border-bottom border-dark">
                                        <thead class="bold">
                                            <tr >
                                                <th scope="row" class="text-lowercase  light col-2 py-1-5">fecha y hora</th>
                                                <th scope="row" class="text-lowercase light col-2 py-1-5">nombre</th>
                                                <th scope="row" class="text-lowercase light col-2 py-1-5">email</th>
                                                <th scope="row" class="text-lowercase light col-2 py-1-5">concesionario</th>
                                                <th scope="row" class="text-lowercase light col-4 py-1-5">mensaje</th>
                                            </tr>
                                        </thead>

                                        <tbody class="text-uppercase body-text-xs light table-group-divider">
                                            <tr *ngFor="let reclamo of reclamos" class="align-star" >
                                                <td class="text-uppercase  ">{{reclamo.fechaCreacion | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                                <td class="text-uppercase  ">{{reclamo.nombre}}</td>
                                                <td class="text-uppercase  ">{{reclamo.email}}</td>
                                                <td class="text-uppercase  ">{{reclamo.concesionario}}</td>
                                                <td class="text-uppercase  ">{{reclamo.mensaje}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div  *ngIf="reclamosnuevos === false">
                                        <p class="h6 light d-flex justify-content-center">No se encuentran reclamos para mostrar</p>
                                    </div>
                                </div>
                            </div>

    <!-- ----------------- -->

                            <div *appMostarRol="['AD', 'CI', 'RI']" class="tab-pane fade" id="archivos-tab-pane" role="tabpanel" aria-labelledby="archivos-tab" tabindex="0">
                                <div class="content p-0 pt-2">
                                    <div  class="row justify-content-between align-items-center p-1 pt-0">
                                        <div class="col-3">
                                            <p class="m-0 my-0-5 p-0 body-text-m bold">{{this.gestionesMensaje}}</p>
                                        </div>
                                        <div class="col-3">
                                            <button class="btn btn-secondary btn-default d-flex justify-content-evenly align-items-center" (click)="activarModal()">
                                                <i class="icon-more icon-sm"></i>
                                                cargar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="content p-0 pb-2">
                                    <table class="table border-1 border-bottom border-dark">
                                        <thead class="bold">
                                            <tr >
                                                <th scope="row" class="text-lowercase  light col-4 py-1-5">fecha y hora</th>
                                                <th scope="row" class="text-lowercase light col-4 py-1-5">nombre público</th>
                                                <th scope="row" class="text-lowercase light col-4 py-1-5">nombre</th>
                                                <th scope="row" class="text-lowercase light col-4 py-1-5 text-center">eliminar</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-uppercase body-text-xs light table-group-divider">
                                            <tr *ngFor="let archivo of archivos"  class="align-star" >
                                                <td class="text-uppercase  ">{{archivo.fechaCreacion| date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                                <td class="text-uppercase  ">{{archivo.nombre}}</td>
                                                <td class="text-uppercase  ">{{archivo.nombreArchivo}}</td>
                                                <td class="text-uppercase text-center "> <button class="btn-delete" (click)="eliminarArchivo(archivo)"><i class="icon icon-close"> </i></button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div  *ngIf="archivosNuevos === false">
                                        <p class="h6 light d-flex justify-content-center">No se encuentran archivos para mostrar</p>
                                    </div>
                                </div>
                            </div>

    <!-- ----------------- -->

                            <div class="tab-pane fade" id="concesionarios-tab-pane" role="tabpanel" aria-labelledby="concesionarios-tab" tabindex="0">

                                <div class="mb-2 mt-1">
                                    <div style="display: flex; width: 100%; justify-content: space-between;">
                                        <h6 class="bold">Acceso a la documentación cargada por los concesionarios</h6>
                                        <div class="col-3">
                                            <button class="btn btn-secondary btn-default d-flex justify-content-evenly align-items-center" (click)="mostrarModalMail()">
                                                <i class="icon-arrow-diagonal icon-sm"></i>
                                                Notificación Masiva
                                            </button>
                                        </div>
                                        <input type="text" placeholder="Buscar nombre o codigo" [(ngModel)]="nombreFiltro">
                                    </div>
                                </div>
                                <div class="content p-0 pb-2">
                                    <table class="table border-1 border-bottom border-dark">
                                        <thead class="bold">
                                            <tr >
                                                <th scope="row" class="text-lowercase  light col-3 py-1-5">CONCESIONARIO</th>
                                                <th scope="row" class="text-lowercase light col-8 py-1-5">NOMBRE</th>
                                                <th scope="row" class="text-lowercase light col-1 py-1-5">ACCEDER</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-uppercase body-text-xs light table-group-divider">
                                            <tr *ngFor="let concesionario of concesionarios | concesionarioFiltro: nombreFiltro"  class="align-star" >
                                                <td class="text-uppercase  ">{{concesionario.id}}</td>
                                                <td class="text-uppercase  ">{{concesionario.nombre}}</td>
                                                <td class="text-uppercase  ">
                                                    <button class="bg-transparent border-0" [routerLink]="['gestor-concesionario' ,concesionario.id]">
                                                    Ingresar
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div  *ngIf="archivosNuevos === false">
                                        <p class="h6 light d-flex justify-content-center">No se encuentran archivos para mostrar</p>
                                    </div>
                                </div>
                            </div>

    <!-- ----------------- -->

                            <div *appMostarRol="['AD', 'SE']" class="tab-pane fade " id="users-tab-pane" role="tabpanel" aria-labelledby="users-tab" tabindex="0">
                                <div class="content p-0 pt-2">
                                    <div  class="row justify-content-between align-items-center p-1 pt-0">

                                        <div class="mb-2 mt-1">
                                            <div style="display: flex; width: 100%; justify-content: space-between;">
                                                <input type="text" placeholder="Buscar por nombre o apellido" style="height: 36px; width: 230px;" [(ngModel)]="usuarioFitro">
                                                <button class="btn btn-secondary btn-default d-flex justify-content-evenly align-items-center" (click)="activarModalUser()">
                                                    <i class="icon-more icon-sm"></i>
                                                    crear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="content p-0 pb-2">
                                    <table class="table border-1 border-bottom border-dark">
                                        <thead class="bold">
                                            <tr >
                                                <th scope="row" class="text-lowercase  light col-3 py-1-5">nombre y apellido</th>
                                                <th scope="row" class="text-lowercase light col-3 py-1-5">mail</th>
                                                <th scope="row" class="text-lowercase light col-1 py-1-5">tipo</th>
                                                <th scope="row" class="text-lowercase light col-3 py-1-5">rol/concesionario</th>
                                                <th scope="row" class="text-lowercase light col-1 py-1-5">estado</th>
                                                <th scope="row" class="text-lowercase light col-1 py-1-5 text-center"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-uppercase body-text-xs light table-group-divider">
                                            <tr *ngFor="let user of usuarios | usuariosFiltro: usuarioFitro"  class="align-star" >
                                                <td class="text-uppercase  ">{{user.nombre}} {{user.apellido}}</td>
                                                <td class="text-uppercase  ">{{user.email}}</td>
                                                <td *ngIf="user.tipo === 'EX'" class="text-uppercase  ">EXTERNO</td>
                                                <td *ngIf="user.tipo === 'IN'" class="text-uppercase  ">INTERNO</td>
                                                <td *ngIf="user.tipo === 'EX'" class="text-uppercase  ">{{user.detalle.nombre}}</td>
                                                <td *ngIf="user.tipo === 'IN'" class="text-uppercase  ">{{user.detalle.descript}}</td>
                                                <td class="text-uppercase  ">{{user.activo}}</td>
                                                <td class="text-center">
                                                    <div class="dropdown">
                                                        <button class="btn-delete" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="icon icon-dots body-text-l"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <div class="d-flex flex-column">
                                                                     <button *ngIf="user.activo !== 'INACTIVO'" class="btn-delete" (click)="confirmacionAccionUsuario(user , 'eliminar')">desactivar </button>
                                                            <button *ngIf="user.activo === 'ACTIVO' || user.activo === 'PENDIENTE'" class="btn-delete" (click)="confirmacionAccionUsuario(user , 'editar')">editar</button>
                                                            <button *ngIf="user.activo === 'PENDIENTE'" class="btn-delete" (click)="confirmacionAccionUsuario(user , 'activar')">enviar mail activación</button>
                                                            <button *ngIf="user.activo === 'INACTIVO'" class="btn-delete" (click)="confirmacionAccionUsuario(user , 'reactivar')">reactivar</button>
                                                            <button *ngIf="user.activo === 'ACTIVO'" class="btn-delete" (click)="confirmacionAccionUsuario(user , 'password')">enviar mail password</button>
                                                            </div>

                                                        </ul>
                                                      </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div  *ngIf="archivosNuevos === false">
                                        <p class="h6 light d-flex justify-content-center">No se encuentran archivos para mostrar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ----------------- -->


<div *ngIf="ModalActivate">
    <div class="modal-carga" >
      <div class="cookies-personalizar-content">
        <div class="cookies-personalizar-header">
          <div class="titulo"><h4 class="bold">Carga de Archivo</h4></div>
          <button class="btn-cerrar h-2 bold" (click)="activarModal()">X</button>
        </div>
        <div class="body">
            <div class="modal-form form-content mx-3">
                <form [formGroup]="formArchivo" >
                    <div class="form-row">
                        <div class="form-group mb-1-5 ">
                            <div class="form-floating">
                                <input formControlName="ctrlNombre" name="nombre" type="text" class="form-control" placeholder="name@example.com" autocomplete="off">
                                <label>Nombre público del archivo</label>
                                <div *ngIf="formArchivo.get('ctrlNombre').touched && formArchivo.get('ctrlNombre').errors?.required" class="text-start" >
                                    <p class="color-error"> El campo es requerido.</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1-5 text-start ">
                            <label for="formFile" class="form-label body-text-xs">Solo formato PDF. Máximo 10mb</label>
                            <input (change)="onFileSelected($event)" data-file-type="ctrlArchivo" class="form-control" type="file" id="formFile" accept=".pdf">
                            <div *ngIf="errorMsgActive" class="text-start" >
                                <p class="color-error">{{errorMsg}}</p>
                            </div>
                        </div>
                        <div class="form-group m-0-5">
                            <button type="submit" class="btn btn-primary btn-default" [disabled]="formArchivo.invalid" (click)="enviarArchivo()"> cargar </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>
</div>


<div *ngIf="ModalMail">
    <div class="modal-carga" >
      <div class="modal-carga-content">
        <div class="cookies-personalizar-header">
          <div class="titulo"><h4 class="bold">notificación masiva</h4></div>
          <button class="btn-cerrar h-2 bold" (click)="ModalMail= false">X</button>
        </div>
        <div class="body modal-carga-body d-inline-block">
            <div class="form-content" >
                <form [formGroup]="formNotificacion" >
                        <div class="form-group my-2">
                            <div class="form-floating my-2">
                              <input formControlName="asunto" name="nombre" type="text" class="form-control" style="width: 100% !important;" placeholder="name@example.com" autocomplete="off"/>
                              <label>Asunto</label>
                              <div *ngIf="formNotificacion.get('asunto')?.touched && formNotificacion.get('asunto').errors?.required" class="text-start" >
                                  <p class="color-error"> El campo es requerido.</p>
                              </div>
                          </div>
                            <div class="form-floating my-2" style="max-height: fit-content;">
                                <div style="text-align: left;">
                                    <div class="row">
                                        <div class="col">
                                            <label class="label color-principal">Seleccionar terminal</label>
                                            <div class="app-footer border-top-bottom my-0-5">
                                                <button *ngFor="let mar of marcas" type="button" class="btn btn-notificacion text-capitalize" data-bs-toggle="button" (click)="setTerminal(mar)" [class.active]="mar === marcaSeleccionada">{{mar}}</button>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label class="label color-principal">Destinatarios</label>
                                            <input #myInput list="opciones" class="form-select my-0-5" (change)="selectDestinatario($event.target.value)"
                                            (keydown.enter)="$event.preventDefault()" [disabled]="disableConcesionarios" placeholder="Buscar concesionarios"
                                            [style.background-color]="disableConcesionarios? 'var(--color-gris-40)' : 'white'">
                                            <datalist id="opciones">
                                                <option *ngFor="let con of conDestinatarios; index as i" value="{{con.nombre}}">{{con.nombre}}</option>
                                            </datalist>
                                            <div class="d-flex flex-wrap mt-1">
                                                <div class="mb-0-25 me-2" *ngFor="let dest of conSeleccionados; index as i" (click)="quitarDestinatario(i)">
                                                    <span class="badge text-bg-dest">
                                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 1L9 9M9 1L1 9" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg> {{dest.nombre}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating my-2">
                                <textarea formControlName="mensaje" name="nombre" type="text" class="form-control textarea" style="width: 100% !important; height: 85px;" placeholder="name@example.com" autocomplete="off"></textarea>
                                <label>Mensaje</label>
                                <div *ngIf="formNotificacion.get('mensaje')?.touched && formNotificacion.get('mensaje').errors?.required" class="text-start" >
                                    <p class="color-error"> El campo es requerido.</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group my-2">
                            <button type="submit" class="btn btn-primary btn-default mt-2" [disabled]="formNotificacion.invalid" (click)="enviarNotificacionMasiva()"> enviar notificación </button>
                        </div>
                </form>
            </div>
        </div>
      </div>
    </div>
</div>

<div *ngIf="ModalUserActivate">
    <div class="modal-carga" >
      <div class="cookies-personalizar-content">
        <div class="cookies-personalizar-header">
          <div class="titulo"><h4 class="bold">Nuevo usuario</h4></div>
          <button class="btn-cerrar h-2 bold" (click)="activarModalUser()">X</button>
        </div>
        <div class="body">
            <div class="modal-form form-content mx-3">
                <form [formGroup]="formUser" >
                    <div class="form-row">
                        <div class="form-group mb-0-5 d-flex justify-content-between">
                            <div class="form-floating" >
                                <input formControlName="nombre" name="nombre" type="text" style="width: 280px !important" class="form-control" placeholder="name@example.com" autocomplete="off">
                                <label>Nombre </label>
                                <div *ngIf="formUser.get('nombre').touched && formUser.get('nombre').errors?.required" class="text-start" >
                                    <p class="color-error"> El campo es requerido.</p>
                                </div>
                                <div *ngIf="formUser.get('nombre').touched && formUser.get('nombre').errors?.minlength" class="text-start" >
                                    <p class="color-error"> Debe tener al menos 3 caracteres</p>
                                </div>
                            </div>
                            <div class="form-floating">
                                <input formControlName="apellido" name="nombre" type="text" style="width: 280px !important" class="form-control" placeholder="name@example.com" autocomplete="off">
                                <label>Apellido </label>
                                <div *ngIf="formUser.get('apellido').touched && formUser.get('apellido').errors?.required" class="text-start" >
                                    <p class="color-error"> El campo es requerido.</p>
                                </div>
                                <div *ngIf="formUser.get('apellido').touched && formUser.get('apellido').errors?.minlength" class="text-start" >
                                    <p class="color-error"> Debe tener al menos 3 caracteres</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-0-5 ">
                            <div class="form-floating">
                                <input formControlName="email" name="nombre" type="text" class="form-control" placeholder="name@example.com" autocomplete="off">
                                <label>Mail </label>
                                <div *ngIf="formUser.get('email').touched && formUser.get('email').errors?.required" class="text-start" >
                                    <p class="color-error"> El campo es requerido.</p>
                                </div>
                                <div *ngIf="formUser.get('email').touched && formUser.get('email').errors?.pattern" class="text-start" >
                                    <p class="color-error"> Debe ingresar un correo válido</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-0-5 ">
                            <div class="form-floating">
                                <select formControlName="tipo" class="form-control form-select" id="floatingSelect" aria-label="Floating label select example" (change)="selecTipo($event.target.value)">
                                    <option selected>Elegir tipo ...</option>
                                    <option value='EX'>Externo</option>
                                    <option value='IN'>Interno</option>
                                </select>
                                <label for="floatingSelect">Tipo usuario</label>
                            </div>
                        </div>

                        <div *ngIf="tipoInterno"  class="form-group mb-0-5 ">
                            <div class="form-floating">
                                <select formControlName="detalle" class="form-control form-select" id="floatingSelect" aria-label="Floating label select example" >
                                  <option value="CI">Control Interno</option>
                                  <option value="RI">Riesgo</option>
                                  <option value="SE">Seguridad</option>
                                  <option value="AD">Administrador</option>
                                </select>
                                <label for="floatingSelect">Perfil</label>
                            </div>
                        </div>
                        <div  *ngIf="tipoExterno" class="form-group mb-0-5 ">
                            <div class="form-floating">
                                <select formControlName="detalle" class="form-control form-select" id="floatingSelect" aria-label="Floating label select example">
                                  <option *ngFor="let concesionario of concesionarios" value="{{concesionario.id}}">{{concesionario.id}} - {{concesionario.nombre}}</option>
                                </select>
                                <label for="floatingSelect">Concesionario</label>
                            </div>
                        </div>
                        <div class="form-group m-0-5">
                            <button type="submit" class="btn btn-primary btn-default" [disabled]="formUser.invalid" (click)="enviarUsuario()"> cargar </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>



      </div>
    </div>
</div>

<div *ngIf="ModalResponseActivate">
    <div class="modal-response" >
        <div class="cookies-personalizar-content">
            <div class="cookies-personalizar-header">
                <div class="titulo">
                    <h5 class="bold"></h5>
                </div>
                <button class="btn-cerrar h-2 bold" (click)="activarModalResponse()">X</button>
            </div>
            <div class="body">
                <div class="modal-form form-content mx-3 mb-2">
                    <h6>{{respEstado}}</h6>
                </div>
                <div class="modal-form form-content mx-3">

                    <p class="body-text-m">{{respMensaje}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="ModalConfirmacion">

    <div class="modal-response"  style="z-index: 99;">
        <div class="cookies-personalizar-content">
          <div class="cookies-personalizar-header">
            <div class="titulo"><h5 class="bold">Eliminar Archivo</h5></div>
            <button type="button" class="close-btn" aria-label="Close" (click)="action(false)">
                <i class="icon-close icon-sm"></i>
            </button>
          </div>
          <div class="body">
              <div class="modal-form form-content mx-3 mt-3">
                <div class="row justify-content-center">
                    <div >
                        <div class="modal-form form-content mb-2">
                            <h6>Desea Eliminar el archivo?</h6>
                        </div>
                    </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" (click)="action(false)" class="btn btn-default btn-primary">
                    NO
                </button>

                <button type="button" (click)="action(true)" class="btn btn-default btn-secondary">
                    SI
                </button>
            </div>
          </div>
        </div>
    </div>
</div>

<div *ngIf="ModalConfirmacionAccionUsuario">

    <div class="modal-response"  style="z-index: 99;">
        <div class="cookies-personalizar-content">
          <div class="cookies-personalizar-header">
            <div class="titulo"><h5 class="bold">{{modalAccionTitulo}}</h5></div>
            <button type="button" class="close-btn" aria-label="Close" (click)="ModalConfirmacionAccionUsuario=false">
                <i class="icon-close icon-sm"></i>
            </button>
          </div>
          <div class="body">
              <div class="modal-form form-content mx-3 mt-3">
                <div class="row justify-content-center">
                    <div >
                        <div class="modal-form form-content mb-2">
                            <h6>{{modalAccionPregunta}}</h6>
                        </div>
                    </div>
                </div>

              </div>
              <div  class="modal-footer">
                <button type="button"(click)="ModalConfirmacionAccionUsuario=false" class="btn btn-default btn-primary">
                    NO
                </button>

                <button *ngIf="modalAccion==='eliminar'" type="button" (click)="eliminarUsuario()" class="btn btn-default btn-secondary">
                    SI
                </button>
                <button *ngIf="modalAccion==='activar' || modalAccion==='reactivar'" type="button" (click)="enviarMailActivacion('activar')" class="btn btn-default btn-secondary">
                    SI
                </button>
                <button *ngIf="modalAccion==='password'" type="button" (click)="enviarMailActivacion('modificar')" class="btn btn-default btn-secondary">
                    SI
                </button>
            </div>
          </div>
        </div>
    </div>
</div>

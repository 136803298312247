<footer class="footer">
  <!-- <div class="footer-container"> -->
    <div class="footer-content">

      <div class="footer-logo-container">
        <img class="footer-logo" src="assets/img/logo-white.png" alt="Logo mobilize" />
      </div>

      <div class="footer-menu">
        <div class="col-a">
          <div class="footer-links ">
            <ul class="list-unstyled">
              <li class="footer-link">
                <a class="link bold">conocenos</a>
              </li>
              <li class="footer-link">
                <a [routerLink]="['/reclamos']" class="link bold">reclamos</a>
              </li>
              <li class="footer-link">
                <a [routerLink]="['/documentacion']" class="link bold">documentación</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-b">
          <div class="footer-links">
            <ul class="list-unstyled mb-0">
              <li class="footer-link">
                <a class="link bold">contactanos</a>
              </li>
              <li class="footer-link">
                <a class="link light">RCI Banque S.A.<br>
                  Sucursal Argentina <br>
                  F. J. S. M. Oro 1744
                  C1414DBB <br>
                  BUENOS AIRES
                  ARGENTINA <br>
                  +54 11 4778 2000</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-c">
          <div class="footer-links">
            <ul class="list-unstyled mb-0">
              <li class="footer-link">
                <a class="link bold">visitá nuestros sitios</a>
              </li>
              <li class="footer-link">
                <a class="link light" href="https://renault.com.ar">renault.com.ar</a>
              </li>
              <li class="footer-link">
                <a class="link light" href="https://nissan.com.ar">nissan.com.ar</a>
              </li>
              <li class="footer-link">
                <a class="link light" href="https://mobilize-fs.com">mobilize-fs.com</a>
              </li>
              <li class="footer-link">
                <a class="link light" href="https://mobilize-wholesale.com.ar">mobilize-wholesale.com.ar</a>
              </li>
              <li class="footer-link">
                <a class="link light" href="https://credinissan.com.ar">credistosnissan.com.ar</a>
              </li>
              <li class="footer-link">
                <a class="link light" href="https://mobilize-protection.com.ar">mobilize-protection.com.ar</a>
              </li>
              <li class="footer-link">
                <a class="link light" href="https://www.tunissanprotegido.com.ar">tunissanprotegido.com.ar</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-d">
          <div class="footer-links" style="border-right: 0px;">
            <ul class="list-unstyled mb-0">
              <li class="footer-link">
                <a href="https://www.renaultproteccion.com.ar/img/npdd.pdf" class="link">normativa protección de datos
                  personales</a>
              </li>
              <li class="footer-link">
                <a href="https://www.argentina.gob.ar/aaip/datospersonales/reclama/30594853667--RL-2020-79796764-APN-DNPDP#AAIP"
                  class="link">base de datos - Argentina.gob.ar</a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</footer>
import { Component } from '@angular/core';
import { TokenService } from './services/auth/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mobilize-wholesale';
  
  constructor(
    private tokenService: TokenService
  ) {

    this.verificarSesion();
  }

  verificarSesion(){
    if(this.tokenService.getToken() != null){
      if(this.tokenService.notExpired()){
        this.tokenService.buscarUsuario();
      }else{
        localStorage.clear();
      }
    }
  }

}
